import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Tooltip,
  Checkbox,
  message,
  Steps,
  Alert,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Step } = Steps;

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepTitles, setStepTitles] = useState([
    'Enter API Key',
    'Validating API Key',
    localStorage.getItem('golemioApiKey') ? 'API Key Validated' : '',
  ]);

  const [form] = Form.useForm();
  const isApiKeySaved = !!localStorage.getItem('golemioApiKey');

  useEffect(() => {
    const savedApiKey = localStorage.getItem('golemioApiKey');
    if (savedApiKey) {
      form.setFieldsValue({ apiKey: savedApiKey });
      setCurrentStep(3);
    }
  }, [form]);

  const onFinish = async (values) => {
    setLoading(true);
    setCurrentStep(1);
    try {
      const response = await fetch(
        'https://api.golemio.cz/v2/vehiclepositions?limit=1',
        {
          headers: { 'x-access-token': values.apiKey },
        }
      );
      if (!response.ok) throw new Error('Network response was not ok');
      if (response.status === 200) {
        localStorage.setItem('golemioApiKey', values.apiKey);
        setStepTitles((prev) => {
          const newTitles = [...prev];
          newTitles[2] = 'API Key Validated';
          return newTitles;
        });
        setCurrentStep(3);
        setShowPopup(true);
        setTimeout(() => {
          window.location.reload();
        }, 3100);
      }
    } catch (error) {
      message.error(
        error.response && error.response.status === 401
          ? 'Invalid API key. Please try again.'
          : 'An error occurred. Please try again later.'
      );
      setStepTitles((prev) => {
        const newTitles = [...prev];
        newTitles[2] = 'Error';
        return newTitles;
      });
      setCurrentStep(3);
      setShowPopup(true);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    localStorage.removeItem('golemioApiKey');
    window.location.reload();
  };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showPopup]);

  useEffect(() => {
    if (showPopup) {
      const fadeOutTimer = setTimeout(() => {
        const popupAlert = document.getElementById('popup-alert');
        if (popupAlert) {
          popupAlert.style.animation = 'fadeOut 1s forwards';
        }
      }, 2000);
      return () => clearTimeout(fadeOutTimer);
    }
  }, [showPopup]);

  return (
    <>
      {showPopup && (
        <Alert
          message={
            stepTitles[2] === 'API Key Validated'
              ? 'Your API key was verified'
              : "Your API key couldn't be verified. Please try again later."
          }
          type={stepTitles[2] === 'API Key Validated' ? 'success' : 'error'}
          showIcon
          style={{
            marginTop: '10px',
            position: 'fixed',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            zIndex: 1000,
            color:
              stepTitles[2] === 'API Key Validated' ? 'darkgreen' : 'darkred',
            opacity: 1,
            animation: 'fadeIn 1s forwards',
          }}
          closable
        />
      )}
      <div
        style={{
          width: '40%',
          margin: '0 auto',
          marginTop: '50px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '14px',
        }}
      >
        <Steps current={currentStep} style={{ marginBottom: 24 }}>
          {stepTitles.map((title, index) => (
            <Step
              key={index}
              title={currentStep >= index ? title : ''}
              status={
                title === 'Error'
                  ? 'error'
                  : currentStep > index
                    ? 'finish'
                    : 'process'
              }
            />
          ))}
        </Steps>
        <Form
          form={form}
          name="login"
          layout="vertical"
          onFinish={onFinish}
          style={{ marginTop: 24, width: '80%' }}
        >
          <Form.Item
            label={
              <span style={{ fontSize: '16px' }}>
                Enter your Golemio API key&nbsp;
                <Tooltip title="We need it to give you access to the app">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
            name="apiKey"
            rules={[
              { required: true, message: 'Please input your GOLEMIO API key!' },
            ]}
          >
            <Input disabled={isApiKeySaved} />
          </Form.Item>
          <Form.Item>
            Get your API key&nbsp;
            <a
              href="https://api.golemio.cz/api-keys"
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontWeight: 'bold' }}
            >
              here
            </a>
          </Form.Item>
          <Form.Item
            name="terms"
            valuePropName="checked"
            initialValue={isApiKeySaved}
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        'You must accept the terms and conditions'
                      ),
              },
            ]}
          >
            <Checkbox disabled={isApiKeySaved}>
              I accept the terms and conditions of Golemio and understand that
              this public key will be saved to your local storage.
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={isApiKeySaved}
              style={{ marginTop: '20px', width: '100%' }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
        <Button
          type="default"
          onClick={handleReset}
          disabled={!isApiKeySaved}
          style={{ marginTop: '-25px', width: '80%' }}
        >
          Reset API Key
        </Button>
      </div>
      <style>
        {`
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }
        `}
      </style>
    </>
  );
};

export default Login;
