import React, { useEffect } from 'react';
import './App.css';
import LineLocation from './components/LineLocation';
import { ConfigProvider } from 'antd';
import { theme as antdTheme } from 'antd';
import { Tabs } from 'antd';
import Departure from './components/Departures';
import Login from './components/Login';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';

function TabNavigation({ isApiKeyValid }) {
  const navigate = useNavigate();
  const location = useLocation();

  const getDefaultActiveKey = () => {
    switch (location.pathname) {
      case '/verification':
        return '1';
      case '/departures':
        return '2';
      case '/position':
        return '3';
      default:
        return '1';
    }
  };

  const handleTabChange = (key) => {
    if (key === '1') navigate('/verification');
    if (key === '2') navigate('/departures');
    if (key === '3') navigate('/position');
  };

  const items = [
    { label: 'Verification', key: '1' },
    { label: 'Departures', key: '2', disabled: !isApiKeyValid },
    { label: 'Location of lines', key: '3', disabled: !isApiKeyValid },
  ];

  return (
    <div
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        backdropFilter: 'blur(10px)',
      }}
    >
      <Tabs
        activeKey={getDefaultActiveKey()}
        centered
        type="type"
        className="Tabs"
        onChange={handleTabChange}
        items={items.map((item) => ({ ...item, key: item.key }))}
      />
    </div>
  );
}

function App() {
  const apiKey = localStorage.getItem('golemioApiKey');
  const isApiKeyValid = apiKey && apiKey !== 'none';

  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgBase: '#3d3b40',
          colorTextBase: '#bfcfe7',
          colorPrimary: '#bfcfe7',
          colorInfo: '#bfcfe7',
          fontSize: 14,
        },
        components: {
          Notification: {
            algorithm: true,
          },
          Select: {
            optionSelectedBg: 'rgba(230,244,255,0.35)',
          },
          Menu: {
            itemSelectedBg: 'rgba(230,244,255,0.5)',
          },
        },
      }}
      algorithm={{ algorithm: antdTheme.darkAlgorithm }}
    >
      <Router>
        <ErrorBoundary isApiKeyValid={isApiKeyValid}>
          <AppContent isApiKeyValid={isApiKeyValid} />
        </ErrorBoundary>
      </Router>
    </ConfigProvider>
  );
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Something went wrong.</h1>
          <TabNavigation isApiKeyValid={this.props.isApiKeyValid} />
        </div>
      );
    }

    return this.props.children;
  }
}

function AppContent({ isApiKeyValid }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isApiKeyValid) {
      navigate('/verification');
    }
  }, [isApiKeyValid, navigate]);

  return (
    <div className="App">
      <TabNavigation isApiKeyValid={isApiKeyValid} />
      <Routes>
        <Route path="/verification" element={<Login />} />
        <Route path="/departures" element={<Departure />} />
        <Route path="/position" element={<LineLocation />} />
        <Route path="*" element={<Navigate to="/verification" />} />
      </Routes>
    </div>
  );
}

export default App;
