'use client';

import React, { useState, useEffect } from 'react';
import {
  Select,
  Table,
  Spin,
  Button,
  Tag,
  Skeleton,
  Card,
  Tooltip,
  Flex,
} from 'antd';
import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
} from '@vis.gl/react-google-maps';

const { Option } = Select;

const StationList = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedEndStation, setSelectedEndStation] = useState(null);
  const [open, setOpen] = useState({});

  const golemioApiKey = localStorage.getItem('golemioApiKey');

  useEffect(() => {
    fetchData();
    fetchAllStops();
  }, [golemioApiKey]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const responses = await Promise.all([
        fetch('https://api.golemio.cz/v2/vehiclepositions?limit=10000', {
          headers: { 'x-access-token': golemioApiKey },
        }),
        fetch(
          'https://api.golemio.cz/v2/vehiclepositions?limit=10000&offset=9999',
          {
            headers: { 'x-access-token': golemioApiKey },
          }
        ),
      ]);

      const results = await Promise.all(responses.map((res) => res.json()));
      const combinedFeatures = results.flatMap((result) => result.features);

      setData(combinedFeatures);
      setFilteredData(combinedFeatures);
    } catch (error) {
      console.error('Error fetching city districts:', error);
      setTimeout(fetchData, 100);
    }
    setLoading(false);
  };

  const fetchAllStops = async () => {
    try {
      const responses = await Promise.all([
        fetch('https://api.golemio.cz/v2/gtfs/stops', {
          headers: { 'x-access-token': golemioApiKey },
        }),
        fetch('https://api.golemio.cz/v2/gtfs/stops?offset=9999', {
          headers: { 'x-access-token': golemioApiKey },
        }),
      ]);
      const results = await Promise.all(responses.map((res) => res.json()));
      const combinedFeatures = results.flatMap((result) => result.features);
      const stops = combinedFeatures.reduce((acc, stop) => {
        acc[stop.properties?.stop_id] = stop.properties.stop_name;
        return acc;
      }, {});
      localStorage.setItem('stopsName', JSON.stringify(stops));
    } catch (error) {
      console.error('Error fetching all stops:', error);
      setTimeout(fetchAllStops, 1000);
    }
  };

  const handleSearch = (value) => {
    const filtered = data.filter((item) =>
      item.properties.trip.gtfs.route_short_name
        .toLowerCase()
        .includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleRouteChange = (value) => {
    setSelectedRoute(value);
    const filtered = data.filter(
      (item) => item.properties.trip.gtfs.route_short_name === value
    );
    setFilteredData(filtered);
  };

  const LastStop = ({ stopId }) => {
    const [stopName, setStopName] = useState('');

    useEffect(() => {
      fetchStopName();
    }, [stopId, golemioApiKey]);

    const fetchStopName = async () => {
      try {
        const stops = JSON.parse(localStorage.getItem('stopName')) || {};
        if (stops[stopId]) {
          setStopName(stops[stopId]);
        } else {
          const responses = await Promise.all([
            fetch(`https://api.golemio.cz/v2/gtfs/stops`, {
              headers: { 'x-access-token': golemioApiKey },
            }),
            fetch(`https://api.golemio.cz/v2/gtfs/stops?offset=9999`, {
              headers: { 'x-access-token': golemioApiKey },
            }),
          ]);
          const results = await Promise.all(responses.map((res) => res.json()));
          const combinedFeatures = results.flatMap((result) => result.features);
          const stops = combinedFeatures.reduce((acc, stop) => {
            acc[stop.properties?.stop_id] = stop.properties.stop_name;
            return acc;
          }, {});
          localStorage.setItem('stopName', JSON.stringify(stops));
          setStopName(stops[stopId] || 'Unknown Stop');
        }
      } catch (error) {
        console.error('Error fetching stop name:', error);
        setTimeout(fetchStopName, 1000);
      }
    };

    return <div>{stopName}</div>;
  };
  const StationZoneId = ({ stopId }) => {
    const [zoneId, setZoneId] = useState('');

    useEffect(() => {
      fetchZoneId();
    }, [stopId, golemioApiKey]);

    const fetchZoneId = async () => {
      try {
        const stops = JSON.parse(localStorage.getItem('stopZone')) || {};
        if (stops[stopId]) {
          setZoneId(stops[stopId] === '-' ? '-' : stops[stopId]);
        } else {
          const responses = await Promise.all([
            fetch(`https://api.golemio.cz/v2/gtfs/stops`, {
              headers: { 'x-access-token': golemioApiKey },
            }),
            fetch(`https://api.golemio.cz/v2/gtfs/stops?offset=9999`, {
              headers: { 'x-access-token': golemioApiKey },
            }),
          ]);
          const results = await Promise.all(responses.map((res) => res.json()));
          const combinedFeatures = results.flatMap((result) => result.features);
          const stops = combinedFeatures.reduce((acc, stop) => {
            acc[stop.properties?.stop_id] = stop.properties.zone_id || '-';
            return acc;
          }, {});
          localStorage.setItem('stopZone', JSON.stringify(stops));
          setZoneId(stops[stopId] || '-');
        }
      } catch (error) {
        console.error('Error fetching zone id:', error);
        setTimeout(fetchZoneId, 1000);
      }
    };

    return <div>{zoneId}</div>;
  };

  const EndStationZoneId = ({ endStation }) => {
    const [zoneId, setZoneId] = useState('');

    useEffect(() => {
      fetchZoneId();
    }, [endStation, golemioApiKey]);

    const fetchZoneId = async () => {
      try {
        const stops = JSON.parse(localStorage.getItem('endStopZone')) || {};
        const normalizedEndStation = endStation.startsWith('Praha,')
          ? endStation.replace('Praha,', '').trim()
          : endStation;

        if (stops[normalizedEndStation]) {
          setZoneId(
            stops[normalizedEndStation] === '-'
              ? '-'
              : stops[normalizedEndStation]
          );
        } else {
          const responses = await Promise.all([
            fetch(`https://api.golemio.cz/v2/gtfs/stops`, {
              headers: { 'x-access-token': golemioApiKey },
            }),
            fetch(`https://api.golemio.cz/v2/gtfs/stops?offset=9999`, {
              headers: { 'x-access-token': golemioApiKey },
            }),
          ]);
          const results = await Promise.all(responses.map((res) => res.json()));
          const combinedFeatures = results.flatMap((result) => result.features);
          const stops = combinedFeatures.reduce((acc, stop) => {
            acc[stop.properties?.stop_name] = stop.properties.zone_id || '-';
            return acc;
          }, {});
          localStorage.setItem('endStopZone', JSON.stringify(stops));
          setZoneId(stops[normalizedEndStation] || '-');
        }
      } catch (error) {
        console.error('Error fetching zone id:', error);
        setTimeout(fetchZoneId, 1000);
      }
    };

    return <div>{zoneId}</div>;
  };

  const generateMarkerKey = (item) => {
    return `${item.id}-${item.properties.trip.gtfs.trip_headsign}-${item.properties.last_position.last_stop.id}-${item.properties.last_position.next_stop.id}-${item.geometry.coordinates[0]}-${item.geometry.coordinates[1]}-${item.properties.trip.gtfs.route_short_name}`;
  };

  const getColorByVehicleType = (vehicleType) => {
    const typeToColorMap = {
      tram: 'blue',
      bus: 'green',
      trolleybus: 'orange',
      metro: 'red',
      night_tram: 'purple',
      night_bus: 'brown',
      privoz: 'cyan',
      train: 'black',
      replacement: 'pink',
      funicular: 'yellow',
      airport_express: 'lightgreen',
      ikea_express: 'lightgreen',
      ricany_public_transport: 'green',
    };
    return typeToColorMap[vehicleType?.toLowerCase()] || 'purple';
  };

  const getVehicleType = (routeShortName) => {
    const routeNumber = parseInt(routeShortName, 10);
    if (!isNaN(routeNumber)) {
      if (routeNumber >= 1 && routeNumber <= 49) return 'TRAM';
      if (routeNumber >= 50 && routeNumber <= 89) return 'TROLLEYBUS';
      if (routeNumber >= 90 && routeNumber <= 99) return 'NIGHT TRAM';
      if (routeNumber >= 100 && routeNumber <= 899) return 'BUS';
      if (routeNumber >= 900 && routeNumber <= 999) return 'NIGHT BUS';
    } else {
      if (routeShortName.startsWith('P')) return 'PŘÍVOZ';
      if (routeShortName.startsWith('L')) return 'FUNICULAR';
      if (routeShortName.startsWith('X')) return 'REPLACEMENT';
      if (routeShortName.startsWith('S') || routeShortName.startsWith('R'))
        return 'TRAIN';
      if (routeShortName.startsWith('AE')) return 'AIRPORT EXPRESS';
      if (routeShortName.startsWith('IKEA')) return 'IKEA EXPRESS';
      if (routeShortName.startsWith('MHD')) return 'ŘÍČANY PUBLIC TRANSPORT';
      if (
        routeShortName.startsWith('A') ||
        routeShortName.startsWith('B') ||
        routeShortName.startsWith('C')
      )
        return 'METRO';
    }
    return 'unknown';
  };

  const renderRouteOptions = () => {
    const routes = [
      ...new Set(
        data.map((item) => item.properties.trip.gtfs.route_short_name)
      ),
    ];

    return routes
      .sort((a, b) => {
        const normalizeRoute = (route) =>
          route.startsWith('X') ? route.slice(1) : route;

        const vehicleTypeA = getVehicleType(a);
        const vehicleTypeB = getVehicleType(b);

        if (vehicleTypeA === 'METRO' && vehicleTypeB !== 'METRO') return -1;
        if (vehicleTypeA !== 'METRO' && vehicleTypeB === 'METRO') return 1;

        const numA = parseInt(normalizeRoute(a), 10);
        const numB = parseInt(normalizeRoute(b), 10);

        if (!isNaN(numA) && !isNaN(numB)) {
          return numA - numB;
        }

        return normalizeRoute(a).localeCompare(normalizeRoute(b));
      })
      .map((route) => {
        const vehicleType = getVehicleType(route);
        const color = getColorByVehicleType(vehicleType);
        const isP0B =
          ['A', 'B', 'C', 'AE'].includes(route) ||
          route.startsWith('L') ||
          route.startsWith('P') ||
          (route.startsWith('X') && parseInt(route.slice(1), 10) < 300) ||
          (route.startsWith('X') &&
            parseInt(route.slice(1), 10) >= 900 &&
            parseInt(route.slice(1), 10) <= 915) ||
          (!isNaN(parseInt(route, 10)) && parseInt(route, 10) < 300) ||
          (!isNaN(parseInt(route, 10)) &&
            parseInt(route, 10) >= 900 &&
            parseInt(route, 10) <= 915);

        const isRegional =
          (route.startsWith('X') &&
            parseInt(route.slice(1), 10) >= 300 &&
            parseInt(route.slice(1), 10) <= 899) ||
          (route.startsWith('X') &&
            parseInt(route.slice(1), 10) >= 916 &&
            parseInt(route.slice(1), 10) <= 999) ||
          (!isNaN(parseInt(route, 10)) &&
            parseInt(route, 10) >= 300 &&
            parseInt(route, 10) <= 899) ||
          (!isNaN(parseInt(route, 10)) &&
            parseInt(route, 10) >= 916 &&
            parseInt(route, 10) <= 999);

        const isStopsEverywhere = route.startsWith('S');
        const isRapid = route.startsWith('R');

        return (
          <Option key={route} value={route} label={route}>
            {route} {/* {selectedRoute !== route && ( */}
            <>
              {isP0B && (
                <Tag color="darkgreen" style={{ float: 'right' }}>
                  P0B
                </Tag>
              )}
              {isRegional && (
                <Tag color="darkred" style={{ float: 'right' }}>
                  REGIONAL
                </Tag>
              )}
              {isStopsEverywhere && (
                <Tag color="darkorange" style={{ float: 'right' }}>
                  STOPS EVERYWHERE
                </Tag>
              )}
              {isRapid && (
                <Tag color="darkblue" style={{ float: 'right' }}>
                  RAPID
                </Tag>
              )}
              <Tag style={{ float: 'right' }} color={color}>
                {vehicleType}
              </Tag>
            </>
            {/* )} */}
          </Option>
        );
      });
  };

  const renderEndStations = () => {
    const endStations = [
      ...new Set(
        filteredData.map((item) => item.properties.trip.gtfs.trip_headsign)
      ),
    ];

    return selectedRoute
      ? endStations.map((station) => {
          const stationData = filteredData
            .filter(
              (item) => item.properties.trip.gtfs.trip_headsign === station
            )
            .sort(
              (a, b) =>
                b.properties.last_position.last_stop.sequence -
                a.properties.last_position.last_stop.sequence
            );
          const isP0B =
            ['A', 'B', 'C', 'AE'].includes(selectedRoute) ||
            selectedRoute.startsWith('L') ||
            selectedRoute.startsWith('P') ||
            (selectedRoute.startsWith('X') &&
              parseInt(selectedRoute.slice(1), 10) < 300) ||
            (selectedRoute.startsWith('X') &&
              parseInt(selectedRoute.slice(1), 10) >= 900 &&
              parseInt(selectedRoute.slice(1), 10) <= 915) ||
            (!isNaN(parseInt(selectedRoute, 10)) &&
              parseInt(selectedRoute, 10) < 300) ||
            (!isNaN(parseInt(selectedRoute, 10)) &&
              parseInt(selectedRoute, 10) >= 900 &&
              parseInt(selectedRoute, 10) <= 915);

          const isRegional =
            (selectedRoute.startsWith('X') &&
              parseInt(selectedRoute.slice(1), 10) >= 300 &&
              parseInt(selectedRoute.slice(1), 10) <= 899) ||
            (selectedRoute.startsWith('X') &&
              parseInt(selectedRoute.slice(1), 10) >= 916 &&
              parseInt(selectedRoute.slice(1), 10) <= 999) ||
            (!isNaN(parseInt(selectedRoute, 10)) &&
              parseInt(selectedRoute, 10) >= 300 &&
              parseInt(selectedRoute, 10) <= 899) ||
            (!isNaN(parseInt(selectedRoute, 10)) &&
              parseInt(selectedRoute, 10) >= 916 &&
              parseInt(selectedRoute, 10) <= 999);

          const isStopsEverywhere = selectedRoute.startsWith('S');
          const isRapid = selectedRoute.startsWith('R');

          return (
            <div key={station} style={{ width: '90%', marginTop: '20px' }}>
              <Card
                title={
                  <>
                    <strong>{selectedRoute}</strong> {station}{' '}
                    <Tag
                      color="#525ceb"
                      style={{
                        color: '#bfcfe7',
                        borderColor: '#bfcfe7',
                        height: '22px',
                      }}
                    >
                      <EndStationZoneId endStation={station} />
                    </Tag>
                  </>
                }
                bordered={false}
                style={{ marginBottom: '20px', border: '1px solid #bfcfe7' }}
              >
                <p>
                  <Tooltip title="Type of vehicle">
                    <Tag
                      color={getColorByVehicleType(
                        getVehicleType(selectedRoute)
                      )}
                    >
                      {getVehicleType(selectedRoute)}
                    </Tag>
                  </Tooltip>
                  <Tooltip title="Zone type">
                    {isP0B && <Tag color="darkgreen">P0B</Tag>}
                    {isRegional && <Tag color="darkred">REGIONAL</Tag>}
                    {isStopsEverywhere && (
                      <Tag color="darkorange">STOPS EVERYWHERE</Tag>
                    )}
                    {isRapid && <Tag color="darkblue">RAPID</Tag>}
                  </Tooltip>
                </p>
                {loading ? (
                  <Spin />
                ) : (
                  <Table
                    dataSource={stationData}
                    columns={columns}
                    rowKey={(record) => generateMarkerKey(record)}
                    pagination={false}
                    style={{
                      marginBottom: '20px',
                      border: '1px solid #bfcfe7',
                    }}
                  />
                )}
              </Card>
            </div>
          );
        })
      : null;
  };

  const columns = [
    {
      title: 'Journey Start',
      dataIndex: 'start_timestamp',
      key: 'start_timestamp',
      render: (text, record) => (
        <Tag
          color="#bfcfe7"
          style={{ color: '#525ceb', borderColor: '#525ceb' }}
        >
          {new Date(record.properties.trip.start_timestamp).toLocaleTimeString(
            [],
            {
              hour: '2-digit',
              minute: '2-digit',
            }
          )}
        </Tag>
      ),
      width: '15%',
    },
    {
      title: 'Last Stop',
      dataIndex: 'last_stop',
      key: 'last_stop',
      render: (text, record) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ float: 'left' }}>
            <LastStop stopId={record.properties.last_position.last_stop.id} />
          </div>
          <Tag
            color="#525ceb"
            style={{
              color: '#bfcfe7',
              borderColor: '#bfcfe7',
              float: 'right',
              height: '22px',
            }}
          >
            <StationZoneId
              stopId={record.properties.last_position.last_stop.id}
            />
          </Tag>
        </div>
      ),
      width: '20%',
    },
    {
      title: 'Departure Time',
      dataIndex: 'last_stop_leave_at',
      key: 'last_stop_leave_at',
      render: (text, record) => (
        <Tag
          color="#f8edff"
          style={{
            color: '#3d3b40',
            borderColor: '#3d3b40',
          }}
        >
          {new Date(
            record.properties.last_position.last_stop.departure_time
          ).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </Tag>
      ),
      width: '15%',
    },
    {
      title: 'Next Stop',
      dataIndex: 'next_stop',
      key: 'next_stop',
      render: (text, record) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ float: 'left' }}>
            <LastStop stopId={record.properties.last_position.next_stop.id} />
          </div>
          <Tag
            color="#525ceb"
            style={{
              color: '#bfcfe7',
              borderColor: '#bfcfe7',
              float: 'right',
              height: '22px',
            }}
          >
            <StationZoneId
              stopId={record.properties.last_position.last_stop.id}
            />
          </Tag>
        </div>
      ),
      width: '20%',
    },
    {
      title: 'Arrival Time',
      dataIndex: 'next_stop_arrive_at',
      key: 'next_stop_arrive_at',
      render: (text, record) => (
        <Tag
          color="#f8edff"
          style={{
            color: '#3d3b40',
            borderColor: '#3d3b40',
          }}
        >
          {new Date(
            record.properties.last_position.next_stop.arrival_time
          ).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </Tag>
      ),
      width: '15%',
    },
    {
      title: 'Delay',
      dataIndex: 'delay',
      key: 'delay',
      render: (text, record) => {
        const delayInSeconds = record.properties?.last_position?.delay?.actual;
        const delay =
          delayInSeconds != null ? Math.round(delayInSeconds / 60) : null;
        const color = delay >= 10 ? 'darkred' : delay > 5 ? 'orange' : 'green';
        return delay != null && delay > 0 ? (
          <Tag color={color}>{delay} min</Tag>
        ) : null;
      },
      width: '10%',
    },
    {
      title: '',
      dataIndex: 'accessibility',
      key: 'accessibility',
      render: (text, record) => {
        const {
          wheelchair_accessible: wheelchairAccessible,
          air_conditioned: airConditioned,
        } = record.properties.trip;
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'nowrap',
            }}
          >
            {wheelchairAccessible && (
              <Tooltip title="Wheelchair accessible">
                <span>&nbsp;♿&nbsp;</span>
              </Tooltip>
            )}
            {airConditioned && (
              <Tooltip title="Equiped with air conditioning">
                {airConditioned && <span>&nbsp;❄️&nbsp;</span>}
              </Tooltip>
            )}
          </div>
        );
      },
      width: '10%',
    },
  ];

  const center = { lat: 50.067467, lng: 14.470242 };

  const renderMarkers = () => {
    return filteredData.map((item) => {
      const markerKey = generateMarkerKey(item);
      return (
        <AdvancedMarker
          key={markerKey}
          position={{
            lat: item.geometry.coordinates[1],
            lng: item.geometry.coordinates[0],
          }}
          title={`Direction: ${item.properties.trip.gtfs.trip_headsign}`}
          onClick={() => {
            // if (
            //   item.properties.last_position &&
            //   item.properties.trip.gtfs.trip_headsign
            // ) {
            //   const { last_stop, next_stop } = item.properties.last_position;
            // if (last_stop && next_stop) {
            setOpen((prevOpen) => ({
              ...prevOpen,
              [markerKey]: !prevOpen[markerKey],
            }));
            // }
            // }
          }}
        >
          <Pin
            background={'#bfcfe7'}
            borderColor={'#3d3b40'}
            glyphColor={'#3d3b40'}
          ></Pin>
        </AdvancedMarker>
      );
    });
  };

  const renderInfoWindows = () => {
    return filteredData.map((item) => {
      const markerKey = generateMarkerKey(item);
      const routeShortName = item.properties.trip.gtfs.route_short_name;
      const vehicleType =
        item.properties.trip.vehicle_type?.description_en || 'unknown';
      const routeNumber = parseInt(routeShortName, 10);

      const typeToColorMap = {
        tram: 'blue',
        bus: 'green',
        trolleybus: 'orange',
        metro: 'red',
        night_tram: 'purple',
        night_bus: 'brown',
        privoz: 'cyan',
        train: 'black',
        replacement: 'pink',
        funicular: 'yellow',
      };

      const getVehicleType = () => {
        if (!isNaN(routeNumber)) {
          if (routeNumber >= 1 && routeNumber <= 49) return 'TRAM';
          if (routeNumber >= 50 && routeNumber <= 89) return 'TROLLEYBUS';
          if (routeNumber >= 90 && routeNumber <= 99) return 'NIGHT TRAM';
          if (routeNumber >= 100 && routeNumber <= 899) return 'BUS';
          if (routeNumber >= 900 && routeNumber <= 999) return 'NIGHT BUS';
        } else {
          if (routeShortName.startsWith('P')) return 'PŘÍVOZ';
          if (routeShortName.startsWith('L')) return 'FUNICULAR';
          if (routeShortName.startsWith('X')) return 'REPLACEMENT';
          if (routeShortName.startsWith('S') || routeShortName.startsWith('R'))
            return 'TRAIN';
          if (
            routeShortName.startsWith('A') ||
            routeShortName.startsWith('B') ||
            routeShortName.startsWith('C')
          )
            return 'METRO';
        }
        return 'unknown';
      };

      const vehicleTypeLabel = getVehicleType();
      const tagColor = typeToColorMap[vehicleType?.toLowerCase()] || 'purple';

      const delayInMinutes = Math.round(
        item.properties.last_position.delay.actual / 60
      );

      const getTagColor = (minutes) => {
        if (minutes > 10) return 'red';
        if (minutes > 5) return 'orange';
        return 'green';
      };

      return delayInMinutes >= 0 ? (
        open[markerKey] ? (
          <InfoWindow
            key={markerKey}
            position={{
              lat: item.geometry.coordinates[1],
              lng: item.geometry.coordinates[0],
            }}
            onCloseClick={() => {
              setOpen((prevOpen) => ({
                ...prevOpen,
                [markerKey]: false,
              }));
            }}
            options={{
              pixelOffset: new window.google.maps.Size(0, -30),
            }}
          >
            <Card
              title={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'left',
                    justifyContent: 'left',
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <Tag
                    color={tagColor}
                    style={{ margin: '10px', marginTop: 0 }}
                  >
                    {`${vehicleTypeLabel} ${routeShortName}`}
                  </Tag>
                  <LastStop
                    stopId={item.properties.last_position.last_stop.id}
                  />

                  <Tag
                    color="#525ceb"
                    style={{
                      color: '#bfcfe7',
                      borderColor: '#bfcfe7',
                      float: 'right',
                      height: '22px',
                      margin: '10px',
                      marginTop: 0,
                    }}
                  >
                    <StationZoneId
                      stopId={item.properties.last_position.last_stop.id}
                    />
                  </Tag>
                </div>
              }
              bordered={false}
              style={{
                margin: 0,
              }}
              className="InfoWindowCard"
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p
                  style={{
                    display: 'flex',
                    alignItems: 'left',
                    justifyContent: 'left',
                  }}
                >
                  Direction: {item.properties.trip.gtfs.trip_headsign}
                </p>
                <Tag
                  color="#525ceb"
                  style={{
                    color: '#bfcfe7',
                    borderColor: '#bfcfe7',
                    float: 'right',
                    height: '22px',
                    margin: '10px',
                    marginTop: 0,
                  }}
                >
                  <EndStationZoneId
                    endStation={item.properties.trip.gtfs.trip_headsign}
                  />
                </Tag>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p
                  style={{
                    display: 'flex',
                    alignItems: 'left',
                    justifyContent: 'left',
                  }}
                >
                  Next Stop:&nbsp;
                  <LastStop
                    stopId={item.properties.last_position.next_stop.id}
                  />
                </p>
                <Tag
                  color="#525ceb"
                  style={{
                    color: '#bfcfe7',
                    borderColor: '#bfcfe7',
                    float: 'right',
                    height: '22px',
                    margin: '10px',
                    marginTop: 0,
                  }}
                >
                  <StationZoneId
                    stopId={item.properties.last_position.last_stop.id}
                  />
                </Tag>
              </div>
              {delayInMinutes > 0 && (
                <p
                  style={{
                    display: 'flex',
                    alignItems: 'left',
                    justifyContent: 'left',
                  }}
                >
                  Delay:&nbsp;
                  {delayInMinutes > 60
                    ? `${Math.floor(delayInMinutes / 60)}h `
                    : ''}
                  <Tag color={getTagColor(delayInMinutes)}>
                    {delayInMinutes} min
                  </Tag>
                </p>
              )}
            </Card>
          </InfoWindow>
        ) : null
      ) : null;
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        position: 'sticky',
        top: 0,
      }}
    >
      <div
        style={{
          color: '#bfcfe7',
          width: '60%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
          paddingBottom: '20px',
        }}
      >
        <div style={{ width: '90%', alignSelf: 'left' }}>
          <h2
            style={{
              color: '#bfcfe7',
              textAlign: 'left',
            }}
          >
            Locate public transport
          </h2>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '90%',
            gap: '10px',
          }}
        >
          <Select
            showSearch
            placeholder="Select a route"
            value={selectedRoute}
            onSearch={handleSearch}
            onChange={(value) => {
              handleRouteChange(value);
              setSelectedEndStation(null);
            }}
            optionLabelProp="label"
            style={{ flex: 1, backgroundColor: '#3d3b40', color: '#bfcfe7' }}
          >
            {renderRouteOptions()}
          </Select>
          <Button
            onClick={() => {
              setSelectedRoute(null);
              setSelectedEndStation(null);
              setFilteredData(data);
            }}
            style={{ backgroundColor: '#3d3b40', color: '#bfcfe7' }}
          >
            Clear Filters
          </Button>
        </div>
        {renderEndStations()}
      </div>
      {selectedRoute ? (
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
          <div
            style={{
              height: 'calc(100vh - 95px)',
              width: '40vw',
              padding: '20px',
              marginTop: '35px',
              paddingLeft: '0px',
            }}
          >
            <Map
              mapId={process.env.REACT_APP_MAPS_API_KEY}
              defaultZoom={9}
              defaultCenter={center}
              options={{
                disableDefaultUI: false,
                zoomControl: true,
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
                draggable: true,
                scrollwheel: true,
                zoom: true,
              }}
            >
              {renderMarkers()}
              {renderInfoWindows()}
            </Map>
          </div>
        </APIProvider>
      ) : (
        <Skeleton.Node
          active={true}
          style={{
            height: 'calc(100vh - 135px)',
            width: 'calc(40vw - 20px)',
            padding: '20px',
            marginTop: '55px',
            marginRight: '20px',
            paddingLeft: '0px',
          }}
        />
      )}
    </div>
  );
};

export default StationList;
