import React, { useState, useEffect, useCallback } from 'react';
import { Select, Table, Button, Tag, Tooltip, Skeleton } from 'antd';
import axios from 'axios';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const FindConnection = () => {
  const [stops, setStops] = useState([]);
  const [selectedStop, setSelectedStop] = useState(null);
  const [departures, setDepartures] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5 });
  const [loading, setLoading] = useState(false);

  const golemioApiKey = localStorage.getItem('golemioApiKey');

  useEffect(() => {
    const fetchStops = async () => {
      setLoading(true);
      try {
        const [stopsResponse, additionalStopsResponse] = await Promise.all([
          axios.get('https://api.golemio.cz/v2/gtfs/stops', {
            headers: {
              'x-access-token': golemioApiKey,
            },
          }),
          axios.get('https://api.golemio.cz/v2/gtfs/stops?offset=9999', {
            headers: {
              'x-access-token': golemioApiKey,
            },
          }),
        ]);

        const combinedData = [
          ...stopsResponse.data.features,
          ...additionalStopsResponse.data.features,
        ];

        const stopsData = combinedData.reduce((acc, { properties }) => {
          const { stop_id, stop_name, zone_id } = properties;
          if (!zone_id || zone_id === '-') return acc;
          const existingStop = acc.find((stop) => stop.stop_name === stop_name);
          existingStop
            ? existingStop.stop_ids.push(stop_id)
            : acc.push({ stop_name, stop_ids: [stop_id], zone_id });
          return acc;
        }, []);

        stopsData.sort((a, b) => {
          const zoneOrder = [
            'P',
            '0',
            'B',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
          ];
          const zoneA = a.zone_id.split(',')[0];
          const zoneB = b.zone_id.split(',')[0];
          return (
            zoneOrder.indexOf(zoneA) - zoneOrder.indexOf(zoneB) ||
            a.stop_name.localeCompare(b.stop_name)
          );
        });

        setStops(stopsData);
      } catch (error) {
        console.error('Error fetching stops:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStops();
  }, [golemioApiKey]);

  const handleStopChange = useCallback(async (value) => {
    setSelectedStop(value);
    setLoading(true);
    try {
      const stopIds = value.split(',');
      const { data } = await axios.get(
        `https://api.golemio.cz/v2/pid/departureboards`,
        {
          params: {
            ids: stopIds,
            minutesBefore: 0,
            minutesAfter: 4320,
            order: 'real',
            limit: 1000,
          },
          headers: { 'x-access-token': golemioApiKey },
        }
      );

      const departuresData = data.departures.map(
        ({ route, trip, delay, departure_timestamp, stop, last_stop }) => ({
          route_short_name: route.short_name,
          trip_headsign: trip.headsign,
          delay_minutes: delay.is_available ? delay.minutes : null,
          departure_timestamp_scheduled: departure_timestamp.scheduled,
          departure_timestamp_minutes: departure_timestamp.minutes,
          platform: stop.platform_code,
          air_conditioned: trip.is_air_conditioned,
          wheelchair_accessible: trip.is_wheelchair_accessible,
          last_stop_name: last_stop?.name || null,
          trip,
          unique_key: `${trip.id}_${departure_timestamp.scheduled}`,
        })
      );

      setDepartures(departuresData);
    } catch (error) {
      console.error('Error fetching departures:', error);
    } finally {
      setLoading(false);
    }
  });

  const handleClearFilters = () => {
    setSelectedStop(null);
    setDepartures([]);
  };

  const handlePaginationChange = (direction) => {
    setPagination((prev) => ({
      ...prev,
      current:
        direction === 'next' ? prev.current + 1 : Math.max(prev.current - 1, 1),
    }));
  };

  const getColorByVehicleType = (vehicleType) => {
    const typeToColorMap = {
      tram: 'blue',
      bus: 'green',
      trolleybus: 'orange',
      metro: 'red',
      night_tram: 'purple',
      night_bus: 'brown',
      privoz: 'cyan',
      train: 'black',
      replacement: 'pink',
      funicular: 'yellow',
      airport_express: 'lightgreen',
    };
    return typeToColorMap[vehicleType?.toLowerCase()] || 'purple';
  };

  const getVehicleType = (routeShortName) => {
    const routeNumber = parseInt(routeShortName, 10);
    if (!isNaN(routeNumber)) {
      if (routeNumber >= 1 && routeNumber <= 49) return 'TRAM';
      if (routeNumber >= 50 && routeNumber <= 89) return 'TROLLEYBUS';
      if (routeNumber >= 90 && routeNumber <= 99) return 'NIGHT TRAM';
      if (routeNumber >= 100 && routeNumber <= 899) return 'BUS';
      if (routeNumber >= 900 && routeNumber <= 999) return 'NIGHT BUS';
    } else {
      if (routeShortName.startsWith('P')) return 'PŘÍVOZ';
      if (routeShortName.startsWith('L')) return 'FUNICULAR';
      if (routeShortName.startsWith('X')) return 'REPLACEMENT';
      if (routeShortName.startsWith('S') || routeShortName.startsWith('R'))
        return 'TRAIN';
      if (routeShortName.startsWith('AE')) return 'AIRPORT EXPRESS';
      if (
        routeShortName.startsWith('A') ||
        routeShortName.startsWith('B') ||
        routeShortName.startsWith('C')
      )
        return 'METRO';
    }
    return 'unknown';
  };

  const EndStationZoneId = ({ endStation }) => {
    const [zoneId, setZoneId] = useState('');

    useEffect(() => {
      fetchZoneId();
    }, [endStation, golemioApiKey]);

    const fetchZoneId = async () => {
      try {
        const stops = JSON.parse(localStorage.getItem('endStopZone')) || {};
        const normalizedEndStation = endStation.startsWith('Praha,')
          ? endStation.replace('Praha,', '').trim()
          : endStation;

        if (stops[normalizedEndStation]) {
          setZoneId(
            stops[normalizedEndStation] === '-'
              ? '-'
              : stops[normalizedEndStation]
          );
        } else {
          const responses = await Promise.all([
            fetch(`https://api.golemio.cz/v2/gtfs/stops`, {
              headers: { 'x-access-token': golemioApiKey },
            }),
            fetch(`https://api.golemio.cz/v2/gtfs/stops?offset=9999`, {
              headers: { 'x-access-token': golemioApiKey },
            }),
          ]);
          const results = await Promise.all(responses.map((res) => res.json()));
          const combinedFeatures = results.flatMap((result) => result.features);
          const stops = combinedFeatures.reduce((acc, stop) => {
            acc[stop.properties?.stop_name] = stop.properties.zone_id || '-';
            return acc;
          }, {});
          localStorage.setItem('endStopZone', JSON.stringify(stops));
          setZoneId(stops[normalizedEndStation] || '-');
        }
      } catch (error) {
        console.error('Error fetching zone id:', error);
        setTimeout(fetchZoneId, 1000);
      }
    };

    return <div>{zoneId}</div>;
  };

  const columns = [
    {
      title: 'Route',
      key: 'route_symbols',
      render: (description_type) => {
        const vehicleType = getVehicleType(description_type.route_short_name);
        const color = getColorByVehicleType(vehicleType);
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'nowrap',
            }}
          >
            <Tag color={color} style={{ marginRight: 8 }}>
              {vehicleType}{' '}
              {description_type.route_short_name || 'No Route Name'}
            </Tag>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'nowrap',
              }}
            >
              {description_type.wheelchair_accessible && (
                <Tooltip title="Wheelchair accessible">
                  <span>&nbsp;♿&nbsp;</span>
                </Tooltip>
              )}
              {description_type.air_conditioned && (
                <Tooltip title="Equipped with air conditioning">
                  <span>&nbsp;❄️&nbsp;</span>
                </Tooltip>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      render: (text) => (
        <Tag
          color="#f8edff"
          style={{
            color: '#3d3b40',
            borderColor: '#3d3b40',
          }}
        >
          {text}
        </Tag>
      ),
    },
    {
      title: 'Destination',
      dataIndex: 'trip_headsign',
      key: 'headsign',
      render: (text, item) => (
        <>
          {text}
          <Tag
            color="#525ceb"
            style={{
              color: '#bfcfe7',
              borderColor: '#bfcfe7',
              float: 'right',
              height: '22px',
              marginLeft: '10px',
            }}
          >
            <EndStationZoneId endStation={item.trip_headsign} />
          </Tag>
        </>
      ),
    },
    {
      title: 'Departure',
      dataIndex: 'departure_timestamp_scheduled',
      key: 'departure_time',
      render: (text) => {
        const departureTime = new Date(text);
        const isNextDay = departureTime.getDate() !== new Date().getDate();
        return (
          <Tag
            color={isNextDay ? 'red' : '#f8edff'}
            style={{
              color: isNextDay ? 'darkRed' : '#3d3b40',
              borderColor: isNextDay ? 'darkRed' : '#3d3b40',
            }}
          >
            {isNextDay && (
              <span>
                {departureTime.getDate() === new Date().getDate() + 1
                  ? 'tomorrow'
                  : departureTime.toLocaleDateString('cs-CZ', {
                      day: '2-digit',
                      month: '2-digit',
                    })}
                &nbsp;
              </span>
            )}
            {departureTime.toLocaleTimeString('cs-CZ', {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Tag>
        );
      },
    },
    {
      title: 'Delay',
      dataIndex: 'delay_minutes',
      key: 'delay',
      render: (text, record) => {
        const color = text >= 10 ? 'red' : text > 5 ? 'orange' : 'green';
        const tooltipTitle = record.last_stop_name
          ? `Current position: ${record.last_stop_name}`
          : "It hasn't started the journey";
        const tooltipColor = record.last_stop_name ? '#bfcfe7' : '#ff4d4f';
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              {text != null && text > 0 && <Tag color={color}>{text} min</Tag>}
            </div>
            <Tooltip
              title={tooltipTitle}
              overlayInnerStyle={{
                backgroundColor: tooltipColor,
                color: '#3d3b40',
                opacity: 0.9,
              }}
              color={tooltipColor}
            >
              <InfoCircleOutlined style={{ color: tooltipColor }} />
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: 'Remaining Time',
      dataIndex: 'departure_timestamp_minutes',
      key: 'remaining_time',
      render: (text) => {
        if (text === '<1' || (typeof text === 'number' && text < 1))
          return 'now';
        const days = Math.floor(text / (60 * 24));
        const hours = Math.floor((text % (60 * 24)) / 60);
        const minutes = text % 60;
        return (
          <span>
            {days > 0 ? `${days} day${days > 1 ? 's' : ''} ` : ''}
            {hours > 0 ? `${hours} hr${hours > 1 ? 's' : ''} ` : ''}
            {minutes > 0 ? `${minutes} min` : ''}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (selectedStop) {
        handleStopChange(selectedStop);
      }
    }, 15000);

    return () => clearInterval(interval);
  }, [selectedStop, handleStopChange]);

  const [paginatedDepartures, setPaginatedDepartures] = useState([]);

  useEffect(() => {
    setPaginatedDepartures(
      departures.slice(
        (pagination.current - 1) * pagination.pageSize,
        pagination.current * pagination.pageSize
      )
    );
  }, [departures, pagination]);

  return (
    <div
      style={{
        marginTop: 20,
        gap: '10px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <div style={{ width: '90%', alignSelf: 'left' }}>
        <h2
          style={{
            color: '#bfcfe7',
            textAlign: 'center',
          }}
        >
          Find departure
        </h2>
      </div>
      <div
        style={{
          marginTop: 20,
          display: 'flex',
          gap: '10px',
          justifyContent: 'center',
        }}
      >
        <Select
          showSearch
          placeholder="Select a stop"
          onChange={(value) => {
            handleStopChange(value);
            setPagination({ ...pagination, current: 1 });
          }}
          value={selectedStop}
          style={{ minWidth: 400, marginBottom: 20 }}
          optionLabelProp="label"
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {stops.map((stop, index) => (
            <Option
              key={`${stop.stop_name}-${stop.stop_ids.join('_')}-${stop.zone_id}-${index}-${stop.stop_ids[0]}-${Math.random()}`}
              value={stop.stop_ids.join(',')}
              label={stop.stop_name}
            >
              {stop.stop_name}{' '}
              <Tag
                style={{ float: 'right', borderColor: '#bfcfe7' }}
                color="#525ceb"
              >
                {stop.zone_id}
              </Tag>
            </Option>
          ))}
        </Select>
        <Button onClick={handleClearFilters}>Clear Filters</Button>
      </div>
      {selectedStop && (
        <>
          <Table
            columns={columns}
            dataSource={paginatedDepartures}
            pagination={false}
            rowKey={(record) => record.unique_key}
            style={{ maxWidth: '80%' }}
          />
          <Button.Group>
            <Button
              onClick={() => handlePaginationChange('prev')}
              disabled={pagination.current <= 1}
              style={{ borderRadius: '4px 0 0 4px' }}
            >
              Show Previous
            </Button>
            <Button
              onClick={() => handlePaginationChange('next')}
              disabled={
                pagination.current * pagination.pageSize >= departures.length
              }
              style={{ borderRadius: '0 4px 4px 0' }}
            >
              Show Next
            </Button>
          </Button.Group>
        </>
      )}
    </div>
  );
};

export default FindConnection;
