// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background-color: #3d3b40ee;
  min-height: 100vh;
}
.App {
  /* background-color: #3d3b40ee; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
}

.PositionList {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  /* background-color: #f8edff11; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #3d3b40;
}
.App-link {
  color: #61dafb;
}

.gm-style .gm-style-iw-c {
  background-color: #3d3b40;
}

.gm-style-iw-d {
  overflow: unset !important;
}

.gm-style-iw-chr button span {
  background-color: #bfcfe7 !important;
}

.gm-style-iw .gm-style-iw-c {
  padding-left: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;AACnB;AACA;EACE,iCAAiC;EACjC,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB;4BAC0B;EAC1B,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,gBAAgB;EAChB,iCAAiC;EACjC,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,cAAc;AAChB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nhtml {\n  background-color: #3d3b40ee;\n  min-height: 100vh;\n}\n.App {\n  /* background-color: #3d3b40ee; */\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  /* align-items: center;\n  justify-content: center; */\n  font-size: calc(10px + 2vmin);\n}\n\n.PositionList {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  min-height: 100%;\n  /* background-color: #f8edff11; */\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  color: #3d3b40;\n}\n.App-link {\n  color: #61dafb;\n}\n\n.gm-style .gm-style-iw-c {\n  background-color: #3d3b40;\n}\n\n.gm-style-iw-d {\n  overflow: unset !important;\n}\n\n.gm-style-iw-chr button span {\n  background-color: #bfcfe7 !important;\n}\n\n.gm-style-iw .gm-style-iw-c {\n  padding-left: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
